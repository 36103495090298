import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../components/layout"
import CommandInfo from "../components/commandInfo"
import SEO from "../components/seo"
import PlatformSelection from "../components/platformSelection"

export default function Template({
  data,
}) {
  const { mdx, commandsJson } = data
  const { body } = (mdx || {})

  const mcWikiUrl = `https://minecraft.gamepedia.com/Commands/${commandsJson.name}`;
  const [platform, setPlatform] = React.useState(
    commandsJson.editions.java ? 'java' : (commandsJson.editions.bedrock ? 'bedrock' : 'education')
  )

  return (
    <Layout>
      <SEO title={commandsJson.name} />
      <div className="blog-post-container">
        <div className="blog-post">
          <CommandInfo command={commandsJson}></CommandInfo>

          <PlatformSelection platform={platform} onChangePlatform={(p) => {
            setPlatform(p);
          }} available={commandsJson.editions} />

          <div className="prose lg:prose-xl mt-8">
            {mdx ? <div>
              <MDXRenderer platform={platform}>{body}</MDXRenderer>
              <a href={mcWikiUrl} rel="noopener">View on Minecraft Gamepedia</a>
            </div> : <p>Currently undocumented. Let's see what the <a href={mcWikiUrl} rel="noopener">Minecraft Gamepedia has to say</a> about this command.</p>}
          </div>
        </div>
      </div>
    </Layout >
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    commandsJson(name: { eq: $slug }) {
      name
      editions {
        java
        bedrock
        education
      }
    }
    mdx(slug: { eq: $slug }) {
      body
      slug
      tableOfContents
    }
  }
`
